import './AboutUs.scss'

import JamesAndDan from '../../Media/IMG_1302.jpg'
import ValveAndPipe2 from '../../Media/2023-10-25 16.50.12.jpg'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const AboutUs = () => {
    return (
        <div className='AboutUs'>
            <Header />
            <div className='aboutus-title-container'>
                <h1>About Us</h1>
            </div>
            <div className='aboutus-subsection-1'>
                <img className='aboutus-image-1' src={JamesAndDan} alt='co-owners' />
                <div className='aboutus-content-container-1'>
                    <h1 className='aboutus-header-1'>Who We Are</h1>
                    <p className='aboutus-paragraph-1'>Welcome to Sungate Systems, where innovation meets tradition. We are a dynamic team of engineers, boasting over 60 years of combined expertise in product design and automation. Our journey is deeply rooted in the rich soil of family farms, which has given us a unique perspective on the challenges faced by today's agricultural industry. Many of our team members, with cherished memories of growing up on a Midwest farm, have always carried the aspiration of bringing their automation experience back to the agricultural world. </p>
                </div>
            </div>
            <div className='aboutus-subsection-2'>
                <h1 className='aboutus-header-2'>Our Vision</h1>
                <p className='aboutus-paragraph-2'>At Sungate Systems, we're not just embracing modern technology; we're returning to our agricultural roots to assist farmers in overcoming the significant challenges of today's farming practices. Our passion for blending cutting-edge automation with a heartfelt connection to farming drives us to make a meaningful impact in the industry. Join us on this exciting journey towards a more efficient and sustainable future for agriculture.</p>
            </div>
            <div className='contact-image-container'>
                {/* ValveAndPipe2 image */}
                <img className='contact-us-image' src={ValveAndPipe2} alt='contact card' />

                {/* Overlay text */}
                <div className='overlay-text'>
                    <p className='contact-us-1'>Any questions? Feel free to contact us!</p>
                    <button className='contact-button' onClick={() => window.location.href = 'mailto:info@sungatesystems.com'}>
                        Contact Us via Email
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs