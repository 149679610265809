//React Imports
import React, { useRef, useEffect } from 'react'
//Styles
import './Home.scss'
//Component Imports
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
//Image Imports
import Rows from '../../Media/IMG_1142.jpg'
import PipeController from '../../Media/IMG_1104.jpeg'
import PipeSideShot from '../../Media/IMG_1110.jpg'
import PipeValve from '../../Media/16.32.30.jpg'
import ValveVideo from '../../Media/SingleOpenCloseClipped.mp4'
import FieldAndController from '../../Media/16.38.48.jpg'
import FieldController from '../../Media/IMG_1146.jpeg'
import BirdsEyeVideo from '../../Media/1154.mp4'
import RowVideo from '../../Media/1151.mp4'


const DynamicVideoPlayer = ({ videoSource }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        video.muted = true;
        video.loop = true;
        video.play();
    }, []);

    return (
        <video className='video-player' ref={videoRef} autoPlay>
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

const Home = () => {
    return (
        <div className='Home'>
            <Header />
            <div className='image-container-1'>
                <div className="image-overlay">
                    <img className='row-of-crops' src={Rows} alt='row of crops' />
                    <div className="image-text">
                        <h1 className='image-text-header'>Automated Surface Irrigation Systems</h1>
                    </div>
                </div>
            </div>
            <div className='content-section-1'>
                <h1 className='section-title-1'>Revolutionizing Crop Care</h1>
                <div className='content-container-1'>
                    <p className='content-1'>Our state-of-the-art automated irrigation system combines innovation and sustainability. Harnessing the power of the sun, it's entirely solar-powered, offering the flexibility to deploy it anywhere, even in remote locations. With convenient mobile device control, you can remotely manage gate valves and irrigation settings while monitoring the progress of your irrigation tasks. Built to withstand the test of time, our system features ruggedized valves and control hardware, ensuring long-lasting reliability.</p>
                    <img className='content-2' src={PipeController} />
                    <img className='content-3' src={PipeSideShot} />
                    <img className='content-4' src={PipeValve} />
                </div>
            </div>
            <div className='content-section-2'>
                <div className='title-container'>
                    <h1 className='section-title-2'>An Irrigation Provider that does things differently</h1>
                </div>
                <div className='paragraph-container'>
                    <p className='content-5'>Our integrated solution promotes sustainability by optimizing water, energy, and labor resources for a responsible and efficient approach to supporting a sustainable food supply and maximizing crop yields.</p>
                </div>
            </div>
            <div className='video-container-1'>
                <DynamicVideoPlayer videoSource={ValveVideo} />
            </div>
            <div className='content-section-3'>
                <div className='subsection-1'>
                    <h1 className='subsection-title'>Reducing Labor Costs</h1>
                    <p className='subsection-paragraph'>
By automating and simplifying control, Sungate Systems alleviates the need for manual on-field labor required to change irrigation sets. This empowers farmers to utilize their labor resources more effectively. </p>
                </div>
                <div className='subsection-2'>
                    <h1 className='subsection-title'>Efficiency Meets Conservation</h1>
                    <p className='subsection-paragraph'>We empower farmers with user-friendly, automated solutions that not only allow them to efficiently manage their irrigation systems but also operate with zero reliance on the grid, ensuring they save valuable resources in the process.</p>
                </div>
                <div className='subsection-3'>
                    <h1 className='subsection-title'>Reviving Tradition with Innovation</h1>
                    <p className='subsection-paragraph'>Our product breathes new life into the age-old method of surface irrigation, preserving the benefits of deep percolation and return flows while integrating cutting-edge tools for enhanced control and customization.</p>
                </div>
            </div>
            <div className='content-section-4'>
                <div className='subsection-4'>
                    <div className='video-container-2'>
                        <DynamicVideoPlayer className='birds-eye-video' videoSource={BirdsEyeVideo} />
                    </div>
                    <div className='image-container-2'>
                        <img className='field-controller' src={FieldController} />
                    </div>
                    <div className='video-container-3'>
                        <DynamicVideoPlayer videoSource={RowVideo} />
                    </div>
                </div>
                <div className='subsection-5'>
                    <div className='content-6'>
                        <h1>At the click of a button</h1>
                        <p>Our irrigation web application is designed with simplicity and user-friendliness in mind. With just a click of a button, you gain the power to effortlessly control and manage your irrigation system. No complex procedures or technical expertise required; our intuitive interface allows you to change irrigation sets with ease.</p>
                    </div>
                </div>
            </div>
            <img className='field-and-controller' src={FieldAndController} />
            <Footer />
        </div>
    )
}

export default Home;
