import { Route, Routes } from 'react-router';
import './App.css';

import Home from '../Home/Home';
import AboutUs from '../AboutUs/AboutUs';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <Home/>
          }
        />
        <Route
          path='/aboutus'
          element={
            <AboutUs/>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
