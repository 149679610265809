import './Header.scss'
import Logo from "../../Media/cropped-waterdroplogo.jpeg"
import Hamburger from '../../Media/icons8-menu-50.png'
import xbutton from '../../Media/icons8-x-100 (1).png'
import { Link } from 'react-router-dom'
import { useState } from 'react'

const Header = () => {
    const [hamburgerOn, setHamburgerOn] = useState(false);
  
    const handleToggleHamburger = () => {
      setHamburgerOn(!hamburgerOn); // Toggle the state
    };
  
    if (hamburgerOn) {
      return (
        <div className='Header-hamburger'>
          <div className='hamburger-subcontainer'>
            <img
              onClick={handleToggleHamburger}
              className='x-button'
              src={xbutton}
              alt='X Button'
            />
            <Link to={'/aboutus'} className='nav-link'>About Us</Link>
          </div>
        </div>
      );
    }
  
    return (
      <div className='Header'>
        <div className='logo-container'>
          <Link to={'/'}>
            <img className='sungate-logo' src={Logo} alt='sungate logo' />
          </Link>
          <h1 className='sungate-name'>SunGate Systems</h1>
        </div>
        <img
          onClick={handleToggleHamburger}
          className='hamburger-button'
          src={Hamburger}
          alt='Hamburger Button'
        />
      </div>
    );
  };
  
  export default Header;