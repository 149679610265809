import './Footer.scss'

const Footer = () => {
    return (
        <div className='Footer'>
            <h1 className='footer-company-name'>Sungate Systems</h1>
            <p className='company-email'>info@sungatesystems.com</p>
        </div>
    )
}

export default Footer